<template>
  <el-drawer
    ref="drawerRef"
    v-model="dialogFormVisible"
    :title="title"
    :size="size"
    :before-close="handleClose"
    :direction="direction"
    custom-class="demo-drawer"
  >
    <div class="containerForm">
      <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
        <el-form-item label="门票名称" prop="categoryName">
          <el-input
            v-model="form.categoryName"
            placeholder="请输入门票集合名称"
          />
        </el-form-item>

        <el-form-item label="门票原价" prop="originalPrice">
          <el-input
            v-model="form.originalPrice"
            type="number"
            placeholder="请输入门票原价"
          />
        </el-form-item>

        <el-form-item label="门票现价" prop="sellingPrice">
          <el-input
            v-model="form.sellingPrice"
            type="number"
            placeholder="请输入门票现价"
          />
        </el-form-item>
        <el-form-item label="门票备注" :label-width="80" prop="description">
          <vab-quill
            v-model="form.remark"
            :min-height="400"
            :options="Richoptions"
          />
        </el-form-item>
        <el-form-item label="门票封面" prop="coverUrl">
          <div class="upLoadImg">
            <wmt-image :src="form.coverUrl" @deleteImg="deleteImg"></wmt-image>
            <div>
              <el-button @click="upLoadLogo">上传图片</el-button>
              <p>建议上传比例为1：1，220px*280px，小于5MB的图片</p>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="门票排序" prop="sort">
          <el-input
            v-model="form.sort"
            placeholder="请输入纯数字"
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            @blur="handleSort"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSave">提交</el-button>
    </div>
  </el-drawer>
  <vab-upload
    ref="vabUploadRef"
    :limit="1"
    name="file"
    :size="2"
    url="/upload"
    @addImg="handleAddImg"
  />

  <vue-cropper
    ref="vabCropperRef"
    @imgInfo="logoInfo"
    :title="'门票封面'"
    :option="brandLogoOption"
  />
</template>
<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    ref,
    toRefs,
  } from 'vue'
  import {
    addTicketCategory,
    updateTicketCategory,
  } from '@/api/exhibitionManage'
  import VabQuill from '@/extra/VabQuill'
  import VabUpload from '@/extra/VabUpload'
  import VueCropper from '@/extra/VueCropper'
  import wmtImage from '@/components/wmt-image'
  export default defineComponent({
    name: 'editConfigDrawer',
    components: {
      VabQuill,
      VabUpload,
      VueCropper,
      wmtImage,
    },
    props: {
      title: {
        type: String,
        default: '新增门票集合页配置',
      },
    },
    setup(props, { emit }) {
      /**
       * @description: cover主k 自定义校验
       * @return {*}
       */
      const validateCover = (rule, value, callback) => {
        if (state.form.coverUrl === '') {
          callback(new Error('请上传门票封面'))
        } else {
          callback()
        }
      }
      const { proxy } = getCurrentInstance()
      const state = reactive({
        form: {
          categoryName: '',
          meetingCode: '',
          originalPrice: '',
          remark: '',
          sellingPrice: '',
          sort: '',
          coverUrl: '',
        },
        brandLogoOption: {
          outputSize: 1, // 裁剪生成图片的质量
          outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
          info: true, // 裁剪框的大小信息
          canScale: true, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: 220, // 默认生成截图框宽度
          autoCropHeight: 280, // 默认生成截图框高度
          fixedBox: true, // 固定截图框大小 不允许改变
          fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
          fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
          canMove: true, // 上传图片是否可以移动
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: true, // 截图框是否被限制在图片里面
          infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
          full: true, // 是否输出原图比例的截图
          enlarge: '1', // 图片根据截图框输出比例倍数
          mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
        },
        rules: {
          categoryName: [
            { required: true, trigger: 'blur', message: '请输入门票集合名称' },
          ],
          // coverUrl: [
          //   { required: true, trigger: 'change', validator: validateCover },
          // ],
          // originalPrice: [
          //   { required: true, trigger: 'blur', message: '请输入门票原价' },
          // ],
          sellingPrice: [
            { required: true, trigger: 'blur', message: '请输入门票现价' },
          ],
        },
        dialogFormVisible: false,
        formRef: null,
        vabCropperRef: null,
        direction: 'rtl',
        size: '40%',
        vabUploadRef: null,
        Richoptions: {
          theme: 'snow',
          bounds: document.body,
          debug: 'warn',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ align: [] }],
                [{ direction: 'rtl' }],
                [{ font: [] }],
                ['clean'],
                ['image'],
                ['lineheight']
              ],
              handlers: {
                image: () => {
                  state['vabUploadRef'].handleShow()
                },
              },
            },
          },
        },
      })

      // 打开
      const handleOpen = (row = {}) => {
        state.form = Object.assign(state.form, row)
        state.dialogFormVisible = true
      }
      // 关闭
      const handleClose = () => {
        // formRef.value.resetFields()
        handleInit()
        state.dialogFormVisible = false
      }

      const upLoadLogo = () => {
        state['vabCropperRef'].dialogVisible = true
      }

      const deleteImg = () => {
        state.form.coverUrl = ''
      }

      const logoInfo = (coverUrl) => {
        console.log('coverUrl', coverUrl)
        state.form.coverUrl = coverUrl
      }

      // 拿到排序的值
      const handleSort = (e) => {
        console.log(e)
        state.form.sort = e.target.value ? Number(e.target.value) : 0
      }
      // 初始化传递的参数
      const handleInit = () => {
        state.form = {
          categoryName: '',
          meetingCode: '',
          originalPrice: '',
          remark: '',
          sellingPrice: '',
          sort: '',
        }
      }
      // 富文本上传图片
      const handleAddImg = async (e) => {
        state.form.remark += `<img src="${e}" />`
      }
      // 提交
      const handleSave = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            console.log('state.form', state.form)
            if (props.title == '添加门票集合页配置') {
              let data = await addTicketCategory(state.form)
            } else {
              let data = await updateTicketCategory(state.form)
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('noticeRefresh')
            handleClose()
          }
        })
      }
      return {
        ...toRefs(state),
        handleSave,
        handleAddImg,
        handleInit,
        handleSort,
        deleteImg,
        upLoadLogo,
        logoInfo,
        handleOpen,
        handleClose,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .containerForm {
    height: 85vh;
    overflow-y: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
